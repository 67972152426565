<template>
  <v-footer padless>
    <v-container class="footer-wrapper" fluid>
      <v-row class="px-5">
        <h3
          class="text-h5 text-md-h3 font-weight-bold font-italic white--text w-100"
        >
          Piletivahetus on imelihtne
        </h3>
        <div
          class="mt-4 text-subtitle-2 text-md-h5 font-weight-light white--text"
        >
          Mõnel meist on taskus lisapilet <br />ja mõni ihkab kultuuriimet
        </div>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.footer-wrapper {
  background: url('~@/assets/img/piletivahetus-on-imelihtne-min.png');
  background-size: cover;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  padding-top: 60px;
  min-height: 600px;
  background-position: center;
}

.column-footer-side {
  padding-bottom: 75px;
}

@media screen and (max-width: 400px) {
  .footer-wrapper {
    min-height: 500px;
    padding-top: 40px;
    background-position: bottom;
    background-position-y: 30px;
  }
  ::v-deep .footer-widgets * {
    padding: 0;
  }
}
</style>
